<template>
    <div class="md-layout-item md-layout md-size-100">
      <div class="md-layout-item md-layout md-gutter mt-8 md-size-100 z-9 align-baseline" style="margin-top: 40px!important;">
        <div class="md-layout-item md-size-70">
          <TextEditor
            class="read"
            label="Question"
            v-model.trim="$v.form.title.$model"
            :message="!$v.form.title.required && $v.form.title.$dirty ? 'Question Field is required' : null"
            />
        </div>
        <div class="md-layout-item md-size-30">
          <MCQAnswer
            class="reading"
            :items="items"
            label="Answers"
            v-model.trim="$v.form.question_options.$model"
            :message="!$v.form.question_options.required && $v.form.question_options.$dirty ? 'Answers Field is required' : null"
          />
        </div>
      </div>
    </div>
</template>

<script>
import TextEditor from "@/components/atom/form/TextEditor";
import MCQAnswer from "@/components/molecule/question/MultipleChoiseAnswer";
import { required } from "vuelidate/lib/validators";
export default {

    components: {
        TextEditor,
        MCQAnswer
    },
    data() {
        return {
           path: 'questions',
            form: {
                title: "",
                question_options: []
            },
            items: [
              {title: '', value: ''},
              {title: '', value: ''},
              {title: '', value: ''},
              {title: '', value: ''}
            ]
        }
    },
    methods: {
      handleFileUpload(value){
        const path = this.path+'/'+value.name;
        this.form.file_path = path.toLowerCase();
      },
    },
    props: ["value"],
    validations: {
        form: {
            title: {required},
            question_options: {required},
        }
    }
}
</script>