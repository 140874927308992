<template>
    <div class="p-2 m-2">
        <div class="flex justify-between align-center">
        <h2>Question</h2>
        <md-button @click="dialog(false)" class="md-icon-button md-dense">
            <md-icon>close</md-icon>
        </md-button>
        </div>
        <div class="p-2 mt-4 flex justify-between align-center rounded border border-solid border-gray-500 w-full p-1">
            <span v-if="contents">
              <span v-for="(item,index) in this.itemList"
                @click="selItem(item)"
                v-bind:class="duplicate === false ? {clicked: opened.includes(item.id)}: 'right'" :key="item.id">
                <span hidden>
                  <div>
                    <input type="checkbox"
                          :id="'item'+index"
                          :value="item.name"
                          v-model="checkedItems">
                  </div>
                </span>
                <span class="content_check">{{ item.name }}</span>
              </span>
            </span>
            <span v-else class="p-5 w-full text-center">
                <h3 class="text-gray">Empty Question</h3>
            </span>
        </div>
        <div class="flex justify-end align-center mt-6">
            <Button
                class="rounded text-black mr-0 border border-solid border-gray-500 mr-4"
                label="Clear"
                type="submit"
                @click="ClearData()"
                >
            </Button>
            <Button
                @click="dialog(false)"
                class="bg-victoria rounded text-white mr-0"
                label="Collect"
                type="submit"
                >
            </Button>
        </div>
    </div>
</template>
<script>
import {
  Button,
} from "@/components";
import {mapMutations } from "vuex";
export default {
  components: {
    Button
  },
  props:{
    contents: {
      default: true
    },
  },
  data() {
    return {
      itemList:[],
      opened:[],
      checkedItems:[],
      data:[],
      store: [],
      duplicate: false
    }
  },

  mounted(){
    const y = this.contents.replace(/<[^>]+>/g, '')
     const x = y.split(' ');
    for( var i = 0; i < x.length; i++){ 
      this.data = {id: i+1,name: x[i],value: i+1}
      this.itemList.push(this.data)
      console.log(this.data)
    }
    
  },
  methods: {
    ...mapMutations({
      dialog: "setShowDialog",
    }),
    selItem(item){
      const index = this.opened.indexOf(item.id);
      if(index > -1){
        this.opened.splice(index,1);
        this.checkedItems.splice(index,1);
        const i = this.store.indexOf(item.name);
        if (i > -1) {
          this.store.splice(i, 1);
        }
      } else {
        this.opened.push(item.id);
        this.store.push(item.name.replace(/,/g, ''))
        this.checkedItems.push(item);
      }
        this.$emit('input', this.store);
        this.duplicate = this.$root.duplicate
    },
    // ClearData(){
    //   console.log(this.store)
    //   this.store.splice(0,this.store.length)
    // }
  },
};
</script>
<style scoped>
.clicked {
  background-color: #f5b325;
  margin: 3px;
  padding: 2px;

}
.content_check{
  margin: 3px;
  cursor: pointer;
  display: inline-block;
}
</style>