<template>
<div>
    <h2
      v-if="label"
      class="pr-4 inline-block mb-1 text-uppercase font-bold text-victoria"
      :class="[
        requiredLabel ? 'required' : '',
        italic ? 'text-italic' : '',
        opacityClass,
        labelClass
      ]"

    > {{ label }} </h2>
    <div class="flex justify-between align-center rounded w-full pt-1 pb-1">
        <div class="flex align-center rounded w-full pt-1 pb-1">
            <h4 class="mr-3 text-uppercase flex align-center">
                Vocabulary Range
                <Info class="ml-1"/>
                <md-tooltip md-direction="top" class="height-auto tooltip_bg_victoria">
                    <div class="flex align-center">
                        <p>It is a long established fact that a reader will<br> be distracted
                            by the readable content of a page when <br>looking at its layout. 
                            The point of using Lorem Ipsum is that<br> it has a more-or-less 
                            normal distribution of letters, as <br>opposed to using 'Content here,
                            content here',<br> making it look like readable English
                        </p>
                    </div>
                </md-tooltip>
            </h4> 
            <h4 class="mr-3 text-victoria cursor relative" v-on:change="onChooseCSV">Upload CSV
            <input type="file" class="upload_button_csv"/></h4>
            <md-button @click="downloadCSV(this)" download="data.csv" class="mr-3 m-0 rounded bg-victoria text-white height-25 z-9 text-capitalize">
            <Download/>  Sample CSV
            </md-button>
        </div>
        <div class="flex justify-right align-center w-full mb-1">
            <md-button @click="onChoosePrompt('3')" class="ml-3 text-capitalize border border-solid border-green-500 p-1 rounded cursor text-success m-0">Vocabulary from Prompt</md-button>
            <md-button @click="onChooseSampleAnswer('4')" class="ml-3 border border-solid text-capitalize bg-japanese-laurel border border-solid border-green-500 rounded text-white p-1 cursor m-0">Vocabulary from Sample Answer</md-button>
        </div>
    </div>
    <div class="rounded border border-solid border-gray-500 w-full p-2">
      <div class="flex justify-between align-center rounded border border-solid border-gray-500 w-full p-1">
        <div class='tag-input'>
          <div v-for='(tag, index) in this.merge' :key='tag' class='tag-input__tag'>
            {{ tag }}
            <button @click='removeTag(index)' type="button" class="md-button md-icon-button md-dense md-input-action md-clear md-theme-default" tabindex="-1"><div class="md-ripple"><div class="md-button-content"><i class="md-icon md-icon-font md-icon-image md-theme-default"><svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path> <path d="M0 0h24v24H0z" fill="none"></path></svg></i></div> </div></button>
          </div>
          <input
            type='text'
            placeholder="Add Contents"
            class='tag-input__text'
            v-on:keydown.enter='addTag'
            v-on:keydown.186='addTag'
            @keydown.delete='removeLastTag'
          />
        </div>
      </div>
      <div class="flex align-center w-full pt-1 pb-1 tag-input_field voc_b_label">
        <div tabindex="0" class="mb-2 md-chip md-theme-default md-deletable md-clickable" v-for="content in content" :key="content">
          <div class="md-ripple">{{content}}</div> 
        </div>
      </div>
    </div>
    <Dialog class="md-dailog__writing" id="dailog_vocabulary">
      <components :is="Component" :contents="contents" @input="input"></components>
    </Dialog>
</div>
</template>


<script>
import {
  Dialog,
} from "@/components";
import {mapMutations } from "vuex";
import {CSVFile} from "@/store/csv";
import Info from '../../../assets/svg/con-info.svg';
import Download from '../../../assets/svg/download.svg';
import ChoosePromptVocabulary from "@/components/molecule/question/ChoosePromptWriting.vue";
import ChooseVocabularySample from "@/components/molecule/question/ChooseVocabularySample.vue";
export default {
    components: {
        Info,
        Download,
        Dialog,
        ChoosePromptVocabulary,
        ChooseVocabularySample
    },
  props: {
    label: {
      type: String,
      default: () => null
    },
    italic: {
      type: Boolean,
      default: () => false
    },
    opacityClass: {
      type: String,
    },
    labelClass: {
      type: String
    },
    requiredLabel: {
      type: Boolean,
      default: () => false
    },
    message: {
      type: String,
      default: () => null
    },
    contents: {
      type: String,
      default: () => null
    },
    content: {
      type: Array,
      default: () => null
    },
  },
  data() {
    return {
        tags: [],
        tag: [],
        tag1: [],
        merge:[],
        Component: '',
        vocabulary: []
    }
  },
  methods: {
    ...mapMutations({
      dialog: "setShowDialog",
    }),
    onChoosePrompt(val){
      setTimeout(() => {
        if(document.getElementById("dailog_teacher")){
          document.getElementById("dailog_teacher").style.display = "none"
        }
        if(document.querySelector("#dailog_writing")){
          document.querySelector("#dailog_writing").style.display = "none"
        }
      },5)
      //console.log(val)
      if(val == '3'){
        this.Component = 'ChoosePromptVocabulary';
        this.dialog(true)
      }
    },
    onChooseSampleAnswer(val){
      setTimeout(() => {
        if(document.getElementById("dailog_teacher")){
          document.getElementById("dailog_teacher").style.display = "none"
        }
        if(document.querySelector("#dailog_writing")){
          document.querySelector("#dailog_writing").style.display = "none"
        }
      },5)
      //console.log(val)
      if(val == '4'){
        this.Component = 'ChooseVocabularySample';
        this.dialog(true)
      }
    },
    addTag(event) {
        event.preventDefault()
        let val = event.target.value.trim()
        if (val.length > 0) {
          if (this.tags.length >= 1) {
            for (let i = 0; i < this.tags.length; i++) {
              if (this.tags[i] === val) {
                return false
              }
            }
          }
          this.tags.push(val)
          event.target.value = ''
          //console.log('tt',this.tags)
          this.FormSendData()
        }
      },
      removeTag(index) {
        this.merge.splice(index, 1); 
        this.tags.splice(index, 1);
        this.FormSendData()
      },
      removeLastTag(event) {
        const index = event.target.value.length
        this.merge.splice(index, 1); 
        this.tags.splice(index, 1);
        //console.log(event.target.value.length)
        this.FormSendData()
      },
      onChooseCSV(e) {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onload = e => 
        this.tag = e.target.result.replace(/\n/g, '').split(';');
        reader.readAsText(file);
        setTimeout(() => this.FormSendData(), 1000);
      },
      input(val){
        this.tag1 =val;
        this.FormSendData()
      },
      FormSendData(){
        this.vocabulary = this.tags.concat(...this.tag1,...this.tag);
        this.$root.duplicate = false
         if(new Set(this.vocabulary).size !== this.vocabulary.length){
          this.$root.duplicate = true
          //alert('Duplicate data has been removed')
        } 
        const merge_arr = [...new Set(this.vocabulary)];
        this.merge = merge_arr.filter(val => !this.content.includes(val));
        //this.merge.splice(this.content, 1);
        //console.log(this.merge)
        this.$emit("input", this.merge);
      },
      downloadCSV() {
        CSVFile()
      }
  }
}
</script>
<style scoped>

/*tag input style*/
  
  .tag-input {
    width: 100%;
    border-radius: 4px;
    font-size: 0.9em;
    min-height: 45px;
    box-sizing: border-box;
    padding: 0 10px;
    font-family: "Roboto";
    margin-bottom: 10px;
  }

  .tag-input__tag {
    font-size: 13px;
    line-height: 32px;
    vertical-align: middle;
    white-space: nowrap;
    border-radius: 5px;
    color: #3c3c3c;
    float: left;
    margin-right: 10px;
    background-color: #0000001f;
    border-radius: 4px;
    margin-top: 10px;
    padding: 0 8px;
    display: flex;
    align-items: center;
  }
  .tag-input__tag button{
    zoom: .6;
    background-color: rgba(0, 0, 0, 0.2);
    margin-left: 15px !important;
    padding: 0;
    margin: 0;
  }

  .tag-input__text {
    border: none;
    outline: none;
    font-size: 1em;
    line-height: 40px;
    background: none;
    padding: 5px;
  }
.upload_button_csv{
    opacity: 0;
    position: absolute;
    left: 0;
    width: 72px;
    cursor: pointer;
}
</style>