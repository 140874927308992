<template>
  <div>
    <h2 class="text-uppercase text-victoria mb-3">{{ label }}</h2>
    <ul>
      <li v-for="(item, index) in items" :key="index" class="my-3 flex align-center">
        <md-radio v-if="dropdown != 'dropdown'" v-model="radio" :value="index" class="md-primary ml-2"> </md-radio>
        <InputFieldComponent v-if="dropdown != 'dropdown'" v-model="item.dd_value" class="w-74" />

        <md-radio v-if="dropdown == 'dropdown'" v-model="radio_check" :value="index" :disabled="index > 0 ? true : false" class="md-primary ml-2"> </md-radio>
        <InputFieldComponent v-if="dropdown == 'dropdown'" v-model="item.dd_value" :disabled="index > -1 ? true : false" class="w-74" />
     
      </li>
    </ul>
    <span class="text-xs text-danger" v-if="message">{{ message }}</span>
  </div>
</template>

<script>
import { InputFieldComponent } from '@/components'
export default {
  components: {
    InputFieldComponent
  },
  data: () => ({
    radio: '1',
    radio_check: 0,    
    data: [],
  }),
  props: {
    label: {
      type: String
    },
    labelOutline: {
      type: Boolean,
      default: () => true
    },
    message: {
      type: String,
      default: () => null
    },
    dropdown: {
      type: String,
      default: () => null
    },
    items: {
      default: true 
    },
    index_array: {
      default: true
    }
  },
  mounted(){
       if(this.dropdown == 'dropdown'){
         this.$root.data;
         this.$emit('input', this.$root.data);
       }
  },
  watch: {
    radio(value) {
      this.items.map(item => {delete item.dd_is_correct_answer,delete item.key});
      this.items[value].dd_is_correct_answer = true;
      this.items.forEach((item, i) => {
        item.dd_key = i + 1;
        item.dd_serial_no = i + 1;
      });
      this.data = {drop_down_options: this.items,serial_no: this.index_array}
      this.$emit('input', this.data);
      console.log('index_array',this.data)
    }
  }
};
</script>

<style></style>
