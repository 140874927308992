<template>
    <div class="md-layout-item md-layout md-size-100">
      <div class="md-layout-item md-layout md-gutter md-size-100 mt-16 z-9 align-baseline" style="margin-top: 40px!important;">
         <div class="md-layout-item md-size-70" id="indexAssign">
            <TextEditor
                class="reading read"
                label="Paragraph"
                @input="input"
                v-model.trim="$v.form.title.$model"
                :message="!$v.form.title.required && $v.form.title.$dirty ? 'Paragraph Field is required' : null"
            />
         </div>
         <div class="md-layout-item md-size-30">
            <DragAndDropInput
                label="Answers"
                class="reading"
                :items="items"
             />
            <div class="reading" v-for="(items, index) in item" :key="index">
                <ul>
                    <li class="my-3 flex align-center">
                        <span class="check_index">{{i > 0 ? i+index+1 : index+1}}</span>
                        <span style="display: contents;">
                            <div class="flex  border-gray-400 w-74 p-0 disable flex-col">
                                <InputFieldComponent @input="input1(items.title)" v-model="items.title" />
                            </div>
                        </span>
                    </li>
                </ul>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
import TextEditor from "@/components/atom/form/TextEditordrag_drop";
import DragAndDropInput from "@/components/atom/DragAndDropInput";
import { InputFieldComponent } from '@/components'
import { required } from "vuelidate/lib/validators";
export default {
    components: {
        DragAndDropInput,
        TextEditor,
        InputFieldComponent
    },
    data() {
        return {
            form: {
                option_type : "GAP_DRAG_DROP",
                title: "",
                question_options: []
            },
            i:[],
            data: [],
            items: [],
            item: [
              {title: '', value: '',index:1},
              {title: '', value: '',index:2},
            ]
        }
    },
    methods:{
        input1(val){
           console.log("VAL", val);
        },
        handleListAllItalicts() {
            const targetDOMs = document.querySelectorAll('#indexAssign i');
            if(targetDOMs.length > 0) {
                window.form_italics = targetDOMs;
                for(let i = 0; i < targetDOMs.length; i++){
                    targetDOMs[i].classList.add("italics_number");
                    targetDOMs[i].id ="italics_number"+`${i+1}`;
                }
            } 
        },
        input(val){
           
        this.handleListAllItalicts();
        const value = val.match(/<i[^>]*>(.*?)<*i>/g);
        if(value != null){
            this.data = value.map(value => value.replace(/<[^>]+>/g, ''));
            this.items = value.map(value => [{value: value.replace(/<[^>]+>/g, '')}]);
            for( var i = 0; i < this.data.length; i++){ 
                this.data[i] = {title: this.data[i],is_correct_answer: true}
                this.i = this.data.length;
                const arr = [
                    ...this.data,
                    ...this.item,
                ].map((item, index) => {
                    delete item.index;
                    return {
                    key: index + 1,
                    serial_no: index + 1,
                    ...item,
                    value: item.title,
                };
                });
                console.log(arr)
                this.form.question_options = arr
            }
        }
       }
    },
    validations: {
        form: {
            title: {required},
            question_options: {required},
        }
    }
}
</script>
<style scoped>
.wd-64 {
    width: 13.3rem;
}
</style>