<template>
      <div class="md-layout-item md-layout md-gutter mt-8 md-size-100 z-9 align-baseline" style="margin-top: 40px!important;">
        <div class="md-layout-item md-size-70">
          <TextEditor
            class="read"
            label="Question"
            v-model.trim="$v.form.title.$model"
            :message="!$v.form.title.required && $v.form.title.$dirty ? 'Question Field is required' : null"
          />
        </div>
        <div class="md-layout-item md-size-30">
          <MultipleSelectAnswer
            class="reading"
            :items="items"
            label="Answers"
            v-model.trim="$v.form.question_options.$model"
            :message="!$v.form.question_options.required && $v.form.question_options.$dirty ? 'Answers Field is required' : null"
          />
        </div>
   </div>
</template>

<script>
import TextEditor from "@/components/atom/form/TextEditor";
import MultipleSelectAnswer from "@/components/molecule/question/MultipleSelectAnswer";
import { required } from "vuelidate/lib/validators";
export default {
    components: {
        TextEditor,
        MultipleSelectAnswer
    },
    data() {
        return {
            form: {
              title: '',
              value: '',
              question_options: [],
            },
            items: [
              {title: '',value: '',is_correct_answer: false},
              {title: '',value: '',is_correct_answer: false},
              {title: '',value: '',is_correct_answer: false},
              {title: '',value: '',is_correct_answer: false}
            ]
        }
    },
    props: ['value'],
    validations: {
        form: {
            title: {required},
            question_options: {required},
        }
    }
}
</script>