<template>
   <div class="md-layout-item md-layout md-size-100">
      <div class="md-layout-item md-layout md-size-100 mt-10">
         <div class="md-layout-item">
            <!-- <DragDrop @input="input"/> -->
            <div class="md-layout-item md-layout md-size-100">
                <div class="md-layout-item md-layout md-size-100 mt-16 align-baseline" style="margin-top: 40px!important;">
                <div class="md-layout-item md-size-100" id="indexAssign">
                    <h2 class="text-uppercase text-victoria mb-3">Question</h2>
                    <TextEditordrag
                        class="read question_note"
                        label="Question of the ROP-use 3rd bracket to add Correct orders[Example-1;Example-2;Example-3....]"
                        @input="input"
                        v-model.trim="$v.form.question.$model"
                        :message="!$v.form.question.required && $v.form.question.$dirty ? 'Question Field is required' : null"
                    />
                </div>
                <div class="md-layout-item md-size-100">
                    <DragAndDropInput
                        label="TARGET"
                        class="re_orderParagraph mt-4"
                        :items="items"
                    />
                </div>
                </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
//import DragDrop from "./ReOrderDragDrop.vue";
import TextEditordrag from "@/components/atom/form/TextEditor";
import DragAndDropInput from "@/components/atom/DragAndDropInput";
import { required } from "vuelidate/lib/validators";
export default {
    components: {
        DragAndDropInput,
        TextEditordrag,
       // DragDrop
    },
    props: ["value"],
    data() {
        return {
           form: {
                incorrect_pairs: [],
                correct_pairs: [],
                question: "",
                question_options: [],
            },
            items: [],
            item: [],
            data:[]
        }
    },
    methods: {
        // input(value){
        //   console.log(value)
        //   this.form.incorrect_pairs = value.incorrect_pairs
        //   this.form.correct_pairs = value.correct_pairs
        // },
        input(val){
           let title = val.match(/[*^[](.*?)]*]/g);
           console.log(title)
            if(title != null){
                this.item = title.map(title => [
                title.toString().replace(/[[^]/g, '').replace(/]/g, '').replace(/&nbsp;/gi,"")
                ]);
                this.items = this.item.map(title => title.toString().split(";"))
                //this.items[0].dd_is_correct_answer = true;
                for( var i = 0; i < this.items.length; i++){ 
                this.items[i] =  this.items[i].map((element)=> ({
                    value: element,
                }))
                this.items.map(item => {delete item.dd_is_correct_answer,delete item.key});
                this.items[i].forEach((item, i) => {
                    item.keys = i + 1;
                    item.serial_no = i + 1;
                });
                this.$root.data = {drop_down_options: this.items[i],serial_no: i+1}
                console.log('index_array',this.data)
                }
                this.form.correct_pairs = this.items[0]
                console.log('after',this.items[0])
            }
        }
    },
    validations: {
        form: {
            question:{required},
        }
    }
}
</script>